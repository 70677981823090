import React from "react";
import Sidenav from "../../Components/Panel/Sidenav/Sidenav";
import Box from "@mui/material/Box";
import Navbar from "../../Components/Panel/Navbar/Navbar";
import { useAppStore } from "../Store";
import AuthorsList from "./AuthorsList";
import { Grid } from "@mui/material";

const Authors = () => {
  return (
   
    <>
    <Navbar />
 <Grid container spacing={2} style={{marginLeft:"-230px",marginTop:"50px"}}>

   <Grid sx={{ display: "flex" }} xs={2}>
     <Sidenav />
     </Grid>
     {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}> */}
     <Grid  xs={10} component="main" sx={{ display: "flex", justifyContent: "center", alignItems: "center",p:3,flexGrow: 0.4, }}>
     <AuthorsList />
     </Grid>
  
 </Grid>
</>
  );
};

export default Authors;
