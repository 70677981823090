import React from "react";
import Sidenav from "../../Components/Panel/Sidenav/Sidenav";
import Box from "@mui/material/Box";
import Navbar from "../../Components/Panel/Navbar/Navbar";
import ArticlesList from "./ArticlesList";
import MediaArticle from "../media/MediaArticle";
import { Grid } from "@mui/material";

const Articles = () => {
  return (

    <>
      <Navbar />
      <Grid container spacing={2} style={{ marginLeft: "-230px", marginTop: "50px" }}>

        <Grid sx={{ display: "flex" }} xs={2}>
          <Sidenav />
        </Grid>
        {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}> */}
        <Grid xs={10} component="main" sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 3, flexGrow: 0.4, }}>
          <ArticlesList />
        </Grid>

      </Grid>
    </>
  );
};

export default Articles;
