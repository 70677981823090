import React, { useState , useEffect} from "react";
import Container from "@mui/material/Container";
import { Grid, Box } from "@mui/material";

import backgroundImage from "../../../assets/background.jpeg";
import People from "../../../assets/people.png";
import Palmyra from "../../../assets/Palmyra.png";
import Dama from "../../../assets/dama.png";
import Nature from "../../../assets/nature.png";
import { ToastContainer, toast } from 'react-toastify';
import HeroCard from "../HeroCard/HeroCard";
import axios from "axios";

export default function Hero() {
  
  // const [newsData, setNewsData] = useState([]);
  // const [newsData2, setNewsData2] = useState([]);
  // const [newslist, setNewsList] = useState([]);
  const[name, setName] = useState();
  const[company, setCompany] = useState();
  const[phone, setPhone] = useState();
  const[email, setEmail] = useState();
  const[notes, setNotes] = useState();
  // useEffect(() => {
  //   const getNew = () => {
  //     axios
  //       .get(
  //         `https://www.tanaghomtech.com/magazine/public/api/getlatestArticlebycategory/1?include=category,writer,country`
  //       )
  //       .then((response) => {
  //         setNewsData(response?.data.data);
  //         console.log(response?.data.data);
        
  //       })
  //       .catch((error) => {
  //         console.error(
  //           "There has been a problem with your fetch operation:",
  //           error
  //         );
        
  //       });
  //   };

  //   const getNew2 = () => {
     

  //     axios
  //       .get(
  //         `https://www.tanaghomtech.com/magazine/public/api/getlatestArticlebycategory/5?include=category,writer,country`
  //       )
  //       .then((response) => {
  //         setNewsData2(response?.data.data);
  //         console.log(response?.data.data);
        
  //       })
  //       .catch((error) => {
  //         console.error(
  //           "There has been a problem with your fetch operation:",
  //           error
  //         );
        
  //       });
  //   };

  //   getNew();
  //   getNew2();
  // },[]);

  // useEffect(() => {
  //   const getPolitics = () => {
  //         axios
  //     .get(
  //       `https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country`
  //     )
  //     .then((response) => {
  //       setNewsList(response?.data.data);
  //       console.log(response?.data.data);
        
  //     })
  //     .catch((error) => {
  //       console.error(
  //         "There has been a problem with your fetch operation:",
  //         error
  //       );
        
  //     });
  // };

  //   getPolitics();
  // },[]);
  const postedData = async () => {
    const notify = () => toast("تم ارسال الطلب ")
    const checkEmail = async (email) => {
      try {
        const checkemailURL = `https://www.tanaghomtech.com/magazine/public/api/member/checkemail?email=${email}`;
        const res = await axios.get(checkemailURL);
        console.log(res.data.data);
        
        if (res.data.data == "ok") {
          try {
            const data = new FormData();
            data.append("name", name);
            data.append("company", company);
            data.append("phone", phone);
            data.append("email", email);
            data.append("notes", notes);
            
            const response = await axios.post('https://www.tanaghomtech.com/magazine/public/api/member', data);
            console.log(response.data);
            notify()
            setEmail("")
            setCompany("")
            setName("")
            setPhone("")
            setNotes("")
          } catch (error) {
            console.error('Error posting data:', error);
          }
        } else {
          toast.error("هذا الايميل موجود مسبقا")
          console.log("nooooo");
        }
      } catch (error) {
        toast.error("الرجاء اعادة الطلب")
      }
    };
    
    // Usage
    checkEmail(email);
    
  }
  
  

  
  return (
    // <Box
    //   sx={{
    //     flexGrow: 1,
    //     marginTop: "1rem",
    //     height: { sx: "auto", md: "100vh" },
    //   }}
    // >
    //   <Grid container spacing={1}>
    //     {/* First card on the left side */}
    //     <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
    //       <Box>
    //         <HeroCard
    //           title={newsData?.title}
    //           category="سياسة"
    //           date={new Date(newsData?.created_at).toLocaleTimeString("default", {
    //             year: "numeric", month: "numeric", day: "numeric", time: "numeric"
    //           })}
    //           backgroundImage={"https://www.tanaghomtech.com/magazine/storage/app/public/"+newsData?.imageLink}
    //           size="91vh"
    //           categoryColor="#FF5733" // Example of passing a dynamic color
    //           link={"/article/"+newsData?.id}
    //         />
    //       </Box>
    //     </Grid>

    //     <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
    //       <Grid container spacing={1}>
    //         {/* Top two cards on the right */}
    //         <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
    //           <HeroCard
    //             title={newsData2.title}
    //             category="الدين والحياة"
    //             date={new Date(newsData.created_at).toLocaleTimeString("default", {
    //               year: "numeric", month: "numeric", day: "numeric", time: "numeric"
    //             })}
    //             backgroundImage={"https://www.tanaghomtech.com/magazine/storage/app/public/"+newsData2.imageLink}
    //             size="45vh"
    //             categoryColor="#FF5733"
    //             link={"/article/"+newsData2.id}
    //           />
    //         </Grid>
    //         <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
    //           <HeroCard
    //             title={newslist[0]?.title}
    //             category="سياسة"
    //             date={new Date(newslist[0]?.created_at).toLocaleTimeString("default", {
    //               year: "numeric", month: "numeric", day: "numeric", time: "numeric"
    //             })}
    //             backgroundImage={"https://www.tanaghomtech.com/magazine/storage/app/public/"+newslist[0]?.imageLink}
    //             size="45vh"
    //             categoryColor="#FF5733"
    //              link={"/article/"+newslist[0]?.id}
    //           />
    //         </Grid>

    //         {/* Bottom card under the top two, spanning their combined width */}
    //         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    //           <HeroCard
    //             title={newslist[1]?.title}
    //             category="سياسة"
    //             date={new Date(newslist[1]?.created_at).toLocaleTimeString("default", {
    //               year: "numeric", month: "numeric", day: "numeric", time: "numeric"
    //             })}
    //             backgroundImage={"https://www.tanaghomtech.com/magazine/storage/app/public/"+newslist[1]?.imageLink}
    //             size="45vh"
    //             categoryColor="#FF5733"
    //              link={"/article/"+newslist[1]?.id}
    //           />
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </Box>
    <>
   <section className="get-in-touch">
   <ToastContainer />
  <h1 className="title">مؤتمر القوى الوطنية الديموقراطية</h1>
  <h4 className="title2">طلب انضمام</h4>
  <form  className="contact-form row" onSubmit={(e) => { e.preventDefault(); postedData(); }}>
    <div className="form-field col-lg-6">
      <input id="name" className="input-text js-input" name="name" value={name} onChange={(e) => setName(e.target.value)} type="text" required />
      <label className="label" htmlFor="name">الاسم<span style={{color:"red"}}>*</span></label>
    </div>
    <div className="form-field col-lg-6">
      <input id="company" className="input-text js-input" name="company" value={company} onChange={(e) => setCompany(e.target.value)}  />
      <label className="label" htmlFor="company">اسم الجهة</label>
    </div>
    <div className="form-field col-lg-6">
      <input id="phone" className="input-text js-input" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} type="text" required />
      <label className="label" htmlFor="phone">رقم الجوال<span style={{color:"red"}}>*</span></label>
    </div>
    <div className="form-field col-lg-6">
      <input id="email" className="input-text js-input" name="email" value={email} onChange={(e) => setEmail(e.target.value)} type="email" required />
      <label className="label" htmlFor="email">الايميل<span style={{color:"red"}}>*</span></label>
    </div>
    <div className="form-field col-lg-12">
      <textarea id="message" className="input-text js-input" name="notes" value={notes} onChange={(e) => setNotes(e.target.value)} ></textarea>
      <label className="label" htmlFor="message">معلومات اخرى</label>
    </div>
    <div className=" col-lg-12">
 
<button type="submit" class="button-82-pushable" role="button">
  <span class="button-82-shadow"></span>
  <span class="button-82-edge"></span>
  <span class="button-82-front text">
   ارسال
  </span>
</button>


     
    </div>
  </form>
</section>

    </>
  );
}
