import React from "react";
import Sidenav from "../../Components/Panel/Sidenav/Sidenav";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Navbar from "../../Components/Panel/Navbar/Navbar";
import "../../Dash.css";
import CategoriesList from "./CategoriesList";
import { Grid } from "@mui/material";
const Categories = () => {
  return (

     <>
     <Navbar />
  <Grid container spacing={2} sx={{marginLeft:"-230px",marginTop:"50px"}} md={{marginLeft:"0px",marginTop:"50px"}} sm={{marginLeft:"0px",marginTop:"50px"}}>

    <Grid item sx={{ display: "flex" }} xs={2}>
      <Sidenav />
      </Grid>
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}> */}
      <Grid  xs={10} component="main" sx={{ display: "flex", justifyContent: "center", alignItems: "center",p:3,flexGrow: 0.4, }}>
      <CategoriesList />
      </Grid>
   
  </Grid>
</>
  );
};

export default Categories;
