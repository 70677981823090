import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardMedia, Chip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { InfoRounded } from '@mui/icons-material';
import Topbar from '../Components/Home/TopBar/Topbar';
import Navbar1 from '../Components/Home/Navbar/Navbar1';
import Navbar2 from '../Components/Home/Navbar/Navbar2';
import { Link, useLocation } from 'react-router-dom';
import Footer from "../Components/Home/Footer/Footer";
import moment from 'moment'
const MediaPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category');

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const imgUrl = "https://www.tanaghomtech.com/magazine/storage/app/public/"
  // Fetch articles based on the category
  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true);
      try {
        const response = await fetch("https://tanaghomtech.com/magazine/public/api/media?filter[category_id]=5&filter[type]=youtube");
        const data = await response.json();
        setArticles(data.data);
        console.log("data", data.data);

      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div style={{ margin: '20px' }}>
      <Navbar1 />
      <Topbar />
      <Navbar2 />
      <Box >
        {loading ? (
          <Typography variant="h6" textAlign="center">
            Loading articles...
          </Typography>
        ) : articles.length > 0 ? (
          <Grid container style={{direction:"rtl"}} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {articles.map((article, index) => (
              <Grid key={index} item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Link style={{textDecoration:"none"}} to={`${article.link}`}>
              <Card
                  variant="outlined"
                  sx={{
                    p: 2,
                    display: 'flex',
                    zIndex: 1,
                    margin: '20px 40px',
                    height: "150px",
                    width: "400px",
                    direction: "rtl"
                  }}
                >
                 
                  <Box sx={{ alignSelf: 'center', ml: 2 }}>


                    <Typography
                      fontWeight="bold"
                      gutterBottom
                      sx={{
                        wordWrap: 'break-word', // Ensures text wraps properly
                        overflow: article.title.length > 25 ? 'visible' : 'hidden',
                        whiteSpace: article.title.length > 25 ? 'normal' : 'nowrap',
                        padding: "10px"
                      }}
                    >
                      {article.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight="regular"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        padding: "10px"
                      }}
                    >
                      {article?.writer?.writerName}
                    </Typography>

                    <Typography
                      sx={{
                        padding: "10px",
                        fontSize: "12px"
                      }}
                      variant="body2"
                      color="text.secondary"
                      fontWeight="regular"
                    >
                      {moment(article.created_at).format("MMM Do YY")}
                    </Typography>
                  </Box>

                </Card>
              </Link>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="h6" textAlign="center">
            No articles found for "{category}".
          </Typography>
        )}
      </Box>
      <Footer/>
    </div>
  );
};

export default MediaPage;
