import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Divider,
  Box,
  Button,
  Stack,
  Modal,
  CircularProgress,
} from "@mui/material";
import axios from "axios"
import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { useAppStore } from "../Store";
import CountryAddForm from "../../Components/Panel/AddForm/CountryAddForm";
import CountryEditForm from "../../Components/Panel/EditForm/CountryEditForm";
import * as XLSX from "xlsx";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MemberList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [editopen, setEditOpen] = useState(false);
  const [formid, setFormid] = useState("");
  const setRows = useAppStore((state) => state.setRows);
  const rows = useAppStore((state) => state.rows);

  useEffect(() => {
    getMember();
  }, []);

  const handleClose = () => setOpen(false);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getMember = () => {
    axios
      .get("https://www.tanaghomtech.com/magazine/public/api/member")
      .then((response) => {
        const data = Array.isArray(response.data.data) ? response.data.data : [];
        setRows(data);
      })
      .catch((error) => {
        console.error("Fetch operation error:", error);
      });
  };

  const deleteCountry = (id) => {
    Swal.fire({
      title: "هل  أنت  متأكد؟ ",
      text: "لن  تتمكن  من التراجع  عن  هذا!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#E1021F",
      confirmButtonText: "نعم،  احذفه",
    }).then((result) => {
      if (result.value) {
        const accessToken = localStorage.getItem("accessToken");
        performDelete(id, accessToken);
      }
    });
  };

  const performDelete = (id, accessToken) => {
    axios
      .delete(`https://www.tanaghomtech.com/magazine/public/api/member/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          Swal.fire("تم الحذف بنجاح", "", "success", { timer: 500 });
          getMember();
        }
      })
      .catch((error) => {
        Swal.fire("خطأ", error.message, "error");
      });
  };

  const exportToExcel = () => {
    // Filter rows to include only specific columns
    const filteredRows = rows.map(({id, name, email, phone, company, notes }) => ({
      Id: id,
      Name: name,
      Email: email,
      Phone: phone,
      Company: company,
      Notes: notes,
    }));
  
    // Convert the filtered data to a worksheet
    const ws = XLSX.utils.json_to_sheet(filteredRows);
  
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Members");
  
    // Write the workbook to a file
    XLSX.writeFile(wb, "MemberList.xlsx");
  };
  

  return (
    <>
      <div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <CountryAddForm closeEvent={handleClose} />
          </Box>
        </Modal>
        <Modal open={editopen}>
          <Box sx={style}>
            <CountryEditForm closeEvent={handleEditClose} fid={formid} />
          </Box>
        </Modal>
      </div>
      {rows.length > 0 ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px" }}>
            <Typography gutterBottom variant="h5" component="div">
              Member List
            </Typography>
            <Button variant="contained" onClick={exportToExcel}>
              Export to Excel
            </Button>
          </Box>
          <Divider />
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow hover key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.company}</TableCell>
                    <TableCell>{row.notes}</TableCell>
                    <TableCell>
                      <Stack spacing={2} direction="row">
                        <DeleteIcon
                          style={{ fontSize: "20px", color: "darkred", cursor: "pointer" }}
                          onClick={() => deleteCountry(row.id)}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <div style={{ height: "70vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default MemberList;
