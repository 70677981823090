import React from 'react'
import Navbar from '../../Components/Panel/Navbar/Navbar'
import { Grid } from '@mui/material'
import Sidenav from '../../Components/Panel/Sidenav/Sidenav'
import MemberList from './MemberList'

const Member = () => {
  return (
    <div>
        <Navbar />
    <Grid container spacing={2} style={{marginLeft:"-230px",marginTop:"50px"}}>

      <Grid sx={{ display: "flex" }} xs={2}>
        <Sidenav />
        </Grid>
        {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}> */}
        <Grid  xs={10} component="main" sx={{ display: "flex", justifyContent: "center", alignItems: "center",p:3,flexGrow: 0.4, }}>
          <MemberList />
        </Grid>
     
    </Grid>
    </div>
  )
}

export default Member
