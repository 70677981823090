import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";

const MediaAdd = ({ closeEvent ,getArticles }) => {
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [url, setUrl] = useState(""); // For storing YouTube URL
    const [file, setFile] = useState(null); // For storing file
    const [type, setType] = useState(""); // The selected type (youtube, image, video)
    const [category, setCategory] = useState([]); // Category options
    const [select, setSelect] = useState(""); // The selected category

    const dropdown = [
        { name: "youtube" },
        { name: "image" },
        { name: "video" },
    ];

    // File change handler
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0]; // Get the first file from the input
        if (selectedFile) {
            setFile(selectedFile); // Store the file in the state
        }
    };

    // Fetch categories from API
    const getCategories = () => {
        axios
            .get("https://www.tanaghomtech.com/magazine/public/api/category")
            .then((response) => {
                const data = Array.isArray(response.data.data) ? response.data.data : [];
                setCategory(data);
            })
            .catch((error) => {
                console.error("There has been a problem with your fetch operation:", error);
            });
    };

    useEffect(() => {
        getCategories(); // Load categories on mount
    }, []);

    // Handle form submission
    const createCategory = () => {
        const token = localStorage.getItem("accessToken"); // Retrieve the token from local storage
        const formdata = new FormData();
        formdata.append("title", title);
        formdata.append("description", description);
        formdata.append("type", type); // The selected type (youtube, image, video)
        formdata.append("category_id", select); // The selected category ID

        // Append URL or file to the form data
        if (type === "youtube" && url) {
            formdata.append("link", url); // Append URL for YouTube type
        } else if (file) {
            formdata.append("link", file); // Append file for image/video types
        }

        axios
            .post("https://www.tanaghomtech.com/magazine/public/api/media/create", formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data", // Use multipart for file uploads
                },
            })
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.error("Error occurred while making request:", error);
            });
            getArticles()
    };

    return (
        <div style={{ width: "100%" }}>
            <Box sx={{ m: 2 }} />
            <Typography variant="h5" align="center">
                اضافة فئة
            </Typography>
            <IconButton
                style={{ position: "absolute", top: "0", right: "0" }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <Box height={10} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        sx={{ minWidth: "100%" }}
                    />
                </Grid>

                {/* Category Selection */}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="category-select-label">Category</InputLabel>
                        <Select
                            labelId="category-select-label"
                            id="category-select"
                            value={select}
                            label="Category"
                            onChange={(e) => setSelect(e.target.value)}
                        >
                            {category?.map((row) => (
                                <MenuItem value={row.id} key={row.id}>
                                    {row?.categoryName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Type Selection */}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="type-select-label">Type</InputLabel>
                        <Select
                            labelId="type-select-label"
                            id="type-select"
                            value={type}
                            label="Type"
                            onChange={(e) => setType(e.target.value)} // Updates type immediately
                        >
                            {dropdown.map((row) => (
                                <MenuItem value={row.name} key={row.name}>
                                    {row.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Description Field */}
                <Grid item xs={12}>
                    <TextField
                        id="outlined-basic"
                        label="Description"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        sx={{ minWidth: "100%" }}
                    />
                </Grid>

                {/* Conditionally render URL input for YouTube */}
                {type === "youtube" && (
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="YouTube Link"
                            variant="outlined"
                            size="small"
                            onChange={(e) => setUrl(e.target.value)}
                            value={url}
                            sx={{ minWidth: "100%" }}
                        />
                    </Grid>
                )}

                {/* Conditionally render file input for image/video */}
                {(type === "image" || type === "video") && (
                    <Grid item xs={12}>
                        <input
                            type="file"
                            accept="video/*,image/*" // Accept both video and image files
                            onChange={handleFileChange}
                            style={{ width: "100%" }}
                        />
                        {/* You can also show a preview or file name if you want */}
                        {file && <Typography variant="body2">Selected file: {file.name}</Typography>}
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Typography variant="h5" align="center">
                        <Button variant="contained" onClick={createCategory}>
                            اضافة
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default MediaAdd;
