import React from 'react'
import Navbar from '../Navbar/Navbar'
import { Box, Grid } from '@mui/material'
import ArticlesList from '../../../Pages/Articels/ArticlesList'
import Sidenav from '../Sidenav/Sidenav'
import MediaArticle from '../../../Pages/media/MediaArticle'

function Media() {
  return (
  
     <>
     <Navbar />
  <Grid container spacing={2} style={{marginLeft:"-230px",marginTop:"50px"}}>
 
    <Grid sx={{ display: "flex" }} xs={2}>
      <Sidenav />
      </Grid>
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}> */}
      <Grid  xs={10} component="main" sx={{ display: "flex", justifyContent: "center", alignItems: "center",p:3,flexGrow: 0.4, }}>
      <MediaArticle />
      </Grid>
   
  </Grid>
 </>
  )
}

export default Media
